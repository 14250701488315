// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { UserRole } from '../app/shared/auth.roles';

export const environment = {
  production: false,
  buyUrl : "https://1.envato.market/6NV1b",
  SCARF_ANALYTICS : false,
  adminRoot: '/app',
  apiUrl: 'https://api.coloredstrategies.com',
  defaultMenuType: 'menu-default',
  subHiddenBreakpoint: 1440,
  menuHiddenBreakpoint: 768,
  themeColorStorageKey: 'vien-themecolor',
  isMultiColorActive: true,
  /*
  Color Options:
  'light.blueyale', 'light.blueolympic', 'light.bluenavy', 'light.greenmoss', 'light.greenlime', 'light.yellowgranola', 'light.greysteel', 'light.orangecarrot', 'light.redruby', 'light.purplemonster'
  'dark.blueyale', 'dark.blueolympic', 'dark.bluenavy', 'dark.greenmoss', 'dark.greenlime', 'dark.yellowgranola', 'dark.greysteel', 'dark.orangecarrot', 'dark.redruby', 'dark.purplemonster'
  */
  defaultColor: 'light.blueyale',
  isDarkSwitchActive: true,
  defaultDirection: 'ltr',
  themeRadiusStorageKey: 'vien-themeradius',
  isAuthGuardActive: true,
  defaultRole: UserRole.Admin,
  firebase: {
    // (narendra)
    // apiKey: "AIzaSyAI416EPxvrfY2D4o3Rn9VPNToq3MKg4bE",
    // authDomain: "mapping-6d3a0.firebaseapp.com",
    // databaseURL: "https://mapping-6d3a0.firebaseio.com",
    // projectId: "mapping-6d3a0",
    // storageBucket: "mapping-6d3a0.appspot.com",
    // messagingSenderId: "515815826270",
    // appId: "1:515815826270:web:3b5fa966818c397d41a5cf",
    // measurementId: "G-WX6ZWPLSF6"
    // (abhipsa)
    // apiKey: "AIzaSyCC6IIVE6a6fdXiWFVogO1ZsJDEzRBwmy8",
    // authDomain: "my-project-demo-249506.firebaseapp.com",
    // databaseURL: "https://my-project-demo-249506.firebaseio.com",
    // projectId: "my-project-demo-249506",
    // storageBucket: "my-project-demo-249506.appspot.com",
    // messagingSenderId: "1016097450099",
    // appId: "1:1016097450099:web:27f219cae3939f514c2206",
    // measurementId: "G-QB44L9MFWT"
  //  (narendrauipep)
    apiKey: "AIzaSyABdz1swGxWq2qdW8dlVlgpToSUDkXofBg",
    authDomain: "mappingnew-ec013.firebaseapp.com",
    databaseURL: "https://mappingnew-ec013.firebaseio.com",
    projectId: "mappingnew-ec013",
    storageBucket: "mappingnew-ec013.appspot.com",
    messagingSenderId: "707134279139",
    appId: "1:707134279139:web:3af23b3369a59c7cb024d6",
    measurementId: "G-40TFPFHLMV"
  }

};
// var db = firebase.firestore();

