import { mid } from './../../../shared/global';
import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { AppConfig } from 'src/app/app.config';


@Injectable({
  providedIn: 'root'
})
export class TsiMappingService {
    // selectedItem =this.selectItem.asObservable();
  // public phone_no= JSON.parse(localStorage.getItem('phonenum'));
  public purchasedItem: any;
  selected: any;
  constructor(public http: HttpClient, private config: AppConfig) {

  }

  // public getCost(item_id) {
  //   console.log("inside get cost");
  //   console.log(item_id);
  //     return this.http.post("https://oblingo.com/api/get_cost", {"doc_id":item_id});
  // }

  public getCost(item_id: any, item: any) {
    console.log('inside get cost');
    console.log(item_id);
    console.log(item);
      // return this.http.get<any>("https://localhost:3000/v1/price", {"doc_id":item_id});
      // return this.http.post<any>("http://localhost:3000/v1/price", {"doc_id":item_id, "item": item});
    return this.http.post<any>(this.config.apiUrl + '/price', {doc_id: item_id, item});

  }

  // public chargeApi(doc_id) {
  //   console.log("inside charge api");
  //   let phone_no= JSON.parse(localStorage.getItem('phonenum'));
  //   console.log(doc_id);
  //   console.log(phone_no);
  //   return this.http.post("https://oblingo.com/api/charge", {"doc_id":doc_id,"phone": phone_no, "multi_pay" : "true"});
  // }

  public getPurchases(token) {
    return this.http.post(this.config.apiUrl + 'client/api/v1/transaction/gettransactions', {
      startdate: new Date('2020-10-07T03:07:20.636Z').toISOString(),
      enddate: new Date().toISOString(),
    },  {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }
  public chargeApi(object, token, mid) {
    return this.http.post(this.config.apiUrl + 'client/api/v1/transaction/create', {...object, merchant_id: mid},  {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  public payNowForMerchant(token, mid) {
    return this.http.post(this.config.apiUrl + 'client/api/v1/pay', {merchant_id: mid},  {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }


  // public tranApi() {
  //   console.log("inside tran api");
  //   return this.http.post(`https://oblingo.com/tran`, {"phone": this.phone_no },{responseType: 'text'});
  // }

}
