export interface IOrder {
  name: string;
  address: string;
  date: string;
  purchase: string;
  statusColor: string;
  status: string;
}


const data: IOrder[] = [
  {
    name: 'Marty Otte',
    address: 'Kansas City, USA',
    date: '02.04.2020',
    purchase: '13/100',
    statusColor: 'secondary',
    status: 'PREMIUM'
  },
  {
    name: 'Linn Ronning',
    address: 'Toronto, Canada',
    date: '02.04.2018',
    purchase: '7/100',
    statusColor: 'primary',
    status: 'STANDARD'
  },
  {
    name: 'Rasheeda Vaquera',
    address: 'Kühndorf, Germany',
    date: '02.02.2019',
    purchase: '2/100',
    statusColor: 'primary',
    status: 'STANDARD'
  },
  {
    name: 'Esperanza Lodge',
    address: 'Montluçon, France',
    date: '20.03.2018',
    purchase: '9/100',
    statusColor: 'primary',
    status: 'PREMIUM'
  },
  {
    name: 'Ken Ballweg',
    address: 'Birmingham, UK',
    date: '17.02.2018',
    purchase: '1/100',
    statusColor: 'secondary',
    status: 'STARTER'
  }
];

export default data;

