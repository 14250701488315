<div [ngClass]="{'landing-page': true,'show-mobile-menu': showMobileMenu}">
    <div class="mobile-menu" (click)="$event.stopPropagation()">
        <a (click)="scrollTo('#home')" href="javascript:;" class="logo-mobile">
            <span></span>
        </a>
    </div>

    <div class="main-container">
        <ngx-headroom class="landing-page-nav">
            <nav>
                <div class="container d-flex align-items-center justify-content-between">
                    <ul class="navbar-nav flex-row">
                        <a (click)="scrollTo('#home')" target="_blank">
                            <img alt="mobile hero" class="mobile-hero" src="/assets/img/products/tsilogo.jpg"
                                style="height:30px; margin-bottom: 0px" />
                        </a>
                        <li class="nav-item" style="margin-left: 0.5rem;"><a (click)="scrollTo('#home')"
                                href="javascript:;">TSI MAPPING</a></li>
                    </ul>
                    <span class="mobile-menu-button" (click)="showMobileMenu=!showMobileMenu; $event.stopPropagation()">
                    </span>
                </div>
            </nav>
        </ngx-headroom>


        <div class="content-container" id="home">
            <div class="section home">
                <div class="container">
                    <div class="row home-row">
                        <div class="col-12 d-block d-md-none">
                            <a  target="_blank">
                                <img alt="mobile hero" class="mobile-hero"
                                    src="/assets/img/products/g2.11 copy.png"/>
                            </a>
                        </div>
                        <div class="col-12 col-xl-4 col-lg-5 col-md-6">
                            <div class="home-text">
                                <div class="display-1">MAPPING<br/>PRODUCTS</div>
                                <p class="white mb-5">
                                    Gain unique insights into your data with mapping solutions from TSI
                                    Once you login with your phone number, you can purchase different packages for calling the TSI Mapping APIs
                                  <br />
                                    <br />
                                    <br />
                                    <!-- Hope you enjoy it! -->
                                </p>
                                <form #loginForm="ngForm" novalidate (ngSubmit)="onSubmit()">
                                    <div class="input-group mb-3">
                                        <input type="text" class="form-control" placeholder="Enter Your Phone Number" aria-label="Enter Your Phone Number"
                                          aria-describedby="basic-addon2" name="MobileNo" [(ngModel)]="MobileNoModel">
                                        <div class="input-group-append">
                                          <button class="btn btn-outline-secondary" (click)="loginWithPhoneMidSecret()"  type="button" style="color: white; border-color: white;">Login</button>
                                        </div>
                                    </div>
                                </form>
                                <form (submit)="onSubmitOtp()" *ngIf="otp" class="input-group mb-3">
                                    <input [(ngModel)]="playerName" class="form-control" [ngModelOptions]="{standalone: true}">
                                    <button type='submit' class="btn btn-outline-secondary">Submit</button>
                                 </form>
                            </div>
                        </div>
                        <div class="col-12 col-xl-7 offset-xl-1 col-lg-7 col-md-6  d-none d-md-block" style="margin-left: 1.33333%;">
                            <a  target="_blank">
                                <img alt="hero" src="/assets/img/products/g2.11 copy.png" style="margin-top: -90px; width: 890px; padding-left: 30px;"/>
                            </a>
                        </div>
                        <div >
                            <div  class="row feature-row">
                                <div class="col-12 col-md-6 col-lg-5 d-flex align-items-center">
                                    <div class="feature-text-container" style="margin-left: 10px;">
                                        <h2 style="color: white ;">The Future of Micropayment Techologies</h2>
                                        <p style="color: white ;" >The Oblingo Payments Technology is the first “enjoy now pay later” platform enabling real
                                            time true micropayments without a  pre-payment demand. No known platform enables a single click, fast registration process with the agility and elegance of Oblingo.
                                        </p>
                                        <p style="color: white ;">Oblingo is led by a seasoned team with decades of deep experience in building franchises of value, including companies specializing in payments, data analytics,
                                             technology solutions, financial services, and application development.</p>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-6 offset-lg-1 offset-md-0 position-relative" style="width: 665px;">
                                    <div class="feature-image-right feature-image-charts position-relative">
                                        <app-player class="video-js side-bar-video card-img-top" style="margin-left: 10px;margin-right: 10px;"[options]="{
                                            preload: 'auto',
                                            controls: true,
                                            poster:'/assets/img/video/Screenshot (5).png',
                                            sources: [{src: '/assets/img/video/Oblingo_UI_DEMO_4.mp4',
                                            type: 'video/mp4'}]}">
                                        </app-player>
                                    </div>

                                    <!-- <div class="col-12 d-block d-md-none" style="width: 665px;">
                                        <app-player class="video-js side-bar-video card-img-top" [options]="{
                                            preload: 'auto',
                                            controls: true,
                                            poster:'/assets/img/video/Screenshot (5).png',
                                            sources: [{src: '/assets/img/video/Oblingo_UI_DEMO_4.mp4',
                                            type: 'video/mp4'}]}">
                                    </app-player> -->
                                        <!-- <img [alt]="feature.title" [src]="feature.img"
                                            class="feature-image-right feature-image-charts position-relative" /> -->
                                </div>
                            </div>
                        </div>

                        <!-- <ngx-headroom class="landing-page-nav">
                            <nav>
                                <div class="container d-flex align-items-center justify-content-between">
                                    <ul class="navbar-nav flex-row">
                                        <a (click)="scrollTo('#home')" target="_blank">
                                            <video>
                                                <source class="mobile-hero" src="/assets\img\video\Oblingo_UI_DEMO_4.mp4" type="video/mp4">
                                            </video>
                                        </a>
                                    </ul>
                                </div>
                            </nav>
                        </ngx-headroom> -->
                        <div class="container copyright pt-5 pb-5">
                            <div class="row">
                                <div class="col-12"></div>
                                <div class="col-12 text-center">
                                    <p class="white mb-5">2022 © TsiMapping</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
