import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-feature-comparison',
  templateUrl: './feature-comparison.component.html'
})
export class FeatureComparisonComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
