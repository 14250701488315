import { Component, OnInit, Renderer2, OnDestroy, HostListener, ElementRef, ViewChild } from "@angular/core";
import { NgForm, FormGroup, Validators, FormControl } from '@angular/forms';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
import { FirebaseService } from 'src/app/services/firebase.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/auth.service';
import data from 'src/app/data/products';
import { ToastrService } from 'ngx-toastr';
import { LocationStrategy } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {UserService} from '../../services/user.service';
// import { NotificationsService, NotificationType } from 'angular2-notifications';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit, OnDestroy {
  @ViewChild('loginForm') loginForm: NgForm;
  showMobileMenu = false;
  playerName: string;
  otp = false;
  // MobileNoModel = '999999999';
  // public submitted = false;
  // buttonDisabled = false;
  // buttonState = '';
  // NgForm: FormGroup;

  constructor(private renderer: Renderer2, public router: Router, private elRef: ElementRef, private http: HttpClient, private userService: UserService,
    public service: FirebaseService, private scrollToService: ScrollToService, private authService: AuthService,
    public toastr: ToastrService, private locationStrategy: LocationStrategy) {
    history.pushState(null, null, window.location.href);
    this.locationStrategy.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
  }

  // MobileNoModel='1234567890'
  public MobileNoModel: any;
  public issignin: boolean;
  public phoneData: boolean;
  public submitted = false;
  buttonDisabled = false;
  buttonState = '';
  NgForm: FormGroup;


  slideSettings = {
    type: "carousel",
    gap: 30,
    perView: 4,
    hideNav: true,
    peek: { before: 10, after: 10 },
    breakpoints: {
      "600": { perView: 1 },
      "992": { perView: 2 },
      "1200": { perView: 3 },
    },
  };


  async ngOnInit() {
    // this.onSubmit();
    let signout = JSON.parse(localStorage.getItem('issignout'));
    console.log(signout);
    if(signout === null) {
      console.log('your not logged in')
      this.router.navigate(['/']);
    }
    else if(!signout){
      this.router.navigate(['/app/dashboards/main']);
    }
    else{
      this.router.navigate(['/']);
    }
    // if(true){
    //   console.log("inside ng oninit");
    //   this.router.navigate(['/app/dashboards/main']);
    // }
    // await this.service.getusers(number);
    this.NgForm = new FormGroup({
      MobileNo: new FormControl([null, [Validators.required, Validators.minLength(10), Validators.maxLength(20)]])
    });
  }

  onSubmit() {
    // console.log(this.MobileNoModel);
    // const number = this.MobileNoModel;
    // localStorage.setItem('phonenum', JSON.stringify(number));
    // this.service.mobiledata(number);
    // this.router.navigate(['/app/dashboards']);
  }

register(number) {
  return this.http.post('http://localhost:5000/client/auth/send_login_otp', {
    "mobileNo": "+919108311018"
  })
}



  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  public Login() {
    this.router.navigate(['/user/login']);
  }




  public async onHome() {
    console.log(this.MobileNoModel);
    this.userService.register( this.MobileNoModel).subscribe(
      data => {
        console.log(data);
        this.otp = true;
        console.log(this.otp);
      },
      error => {
        console.log(error)
      }
    )
    // let isSignIn = true;
    const number = this.MobileNoModel;
    localStorage.setItem('phonenum', JSON.stringify(number));
    localStorage.setItem('issignout',JSON.stringify(false));
    // const signin = this.issignin;
    // localStorage.setItem('signIn', JSON.stringify(signin));
    // console.log(signin);
    // let temp =  await this.service.getusers(number);
    // if (temp.empty) {
    //   console.log('No matching documents.');
    //   return ;
    // }
    // // console.log(doc);
    // temp.forEach(doc => {
    //   // console.log(doc.id, '=>', doc.data());
    //   // return doc.data();
    //   console.log(doc.data().issignin);
    // });

    // console.log(temp)
  //  if(temp!= null){
  //  if(temp.issignin ){
  //    console.log()
  //  }

  //  }
    // this.service.mobiledata(number);
    // if (number) {
    //   if (number.length === 10) {
    //      await this.service.mobiledata(number);
    //     // this.service.getusers();
    //     // await this.service.getusers(number);
    //     // console.log(this.service.getusers());
    //     console.log('you entered into login');
    //     this.router.navigate(['/app/dashboards/main']);
    //   } else {
    //     this.toastr.error("Please Enter Correct Mobile Number");
    //   }
    // } else {
    //   this.toastr.error("Please Enter Your Mobile Number")
    // }

    // this.router.navigate(['/app/dashboards/main']);
  }
  public loginWithPhoneMidSecret() {
    this.userService.loginWithPhoneMidSecret(this.MobileNoModel).subscribe(
      (data: any) => {
        console.log(data);
        localStorage.setItem('token', data.data.token);
        if (data && data.data && data.data.isProfileUpdated === false) {
          this.toastr.error("Profile registration not completed yet. visit oblingo.com to complete your profile");
        }
        localStorage.setItem('userid', data.data.id);
        localStorage.setItem('phonenum', JSON.stringify(this.MobileNoModel));
        localStorage.setItem('issignout', JSON.stringify(false));
        this.router.navigate(['/app/dashboards/main']);
      },
      error => {
        if (error && error.error && error.error.message === "User not exists") {
          this.toastr.error("You havent registered in oblingo. please signup at oblingo.com");
        }
        else if (error && error.error && error.error.message) {
          this.toastr.error(error.error.message);
        }
        else {
          this.toastr.error("Please Enter Correct Mobile Number");
        }
        console.log(error);
      }
    );
  }
  public onSubmitOtp() {
  console.log(this.playerName);
  let value={
    number: '',
    code: ''
  };
  value.number = this.MobileNoModel;
  value.code = this.playerName;
  this.userService.otp(value).subscribe(
    (data: any) => {
      console.log(data);
      localStorage.setItem('token', data.data.token);
      localStorage.setItem('userid', data.data.id);
      this.router.navigate(['/app/dashboards/main']);
    },
    error => {
      this.toastr.error("Please Enter Correct Mobile Number");
      console.log(error)
    }
  )
}

  ngOnDestroy() {
    this.renderer.removeClass(document.body, "no-footer");
  }

  // @HostListener("window:resize", ["$event"])
  // onResize(event) {
  // const homeRect = this.elRef.nativeElement
  // .querySelector(".home-row")
  // .getBoundingClientRect();

  // const homeSection = this.elRef.nativeElement.querySelector(
  // ".landing-page .section.home"
  // );
  // homeSection.style.backgroundPositionX = homeRect.x - 580 + "px";

  // const footerSection = this.elRef.nativeElement.querySelector(
  // ".landing-page .section.footer"
  //   );
  //   footerSection.style.backgroundPositionX = event.target.innerWidth - homeRect.x - 2000 + "px";

  //   if (event.target.innerWidth >= 992) {
  //     this.renderer.removeClass(
  //       this.elRef.nativeElement.querySelector(".landing-page"),
  //       "show-mobile-menu"
  //     );
  //   }
  // }

  @HostListener("window:click", ["$event"])
  onClick(event) {
    this.showMobileMenu = false;
  }

  @HostListener("window:scroll", ["$event"])
  onScroll(event) {
    this.showMobileMenu = false;
  }

  scrollTo(target) {
    const config: ScrollToConfigOptions = {
      target,
      offset: -150
    };

    this.scrollToService.scrollTo(config);
  }
}
// if (!this.loginForm.valid || this.buttonDisabled) {
//   // return;
// }
// this.buttonDisabled = true;
// this.buttonState = 'show-spinner';
// this.authService.signIn(this.loginForm.value).then(user => {
//   this.router.navigate([environment.adminRoot]);
// }).catch((error) => {
//   this.buttonDisabled = false;
//   this.buttonState = '';
//   this.notifications.create('Error', error.message, NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false });
// })

