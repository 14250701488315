import { Injectable } from '@angular/core';
declare var CollectJS:any;
@Injectable()
export class AppConfig {
  public apiUrl = '';
  public mid = '';
  public secret = '';
  public apiAdminUrl = '';
  public stripePublicKey = '';
  constructor() {
    this.getConstants();
  }
  public getConstants() {
    if ( window.location.hostname === 'localhost') {
    // if (window.location.hostname === 'ec2-34-220-226-52.us-west-2.compute.amazonaws.com') {
      this.apiUrl = window.location.protocol + '//' + window.location.hostname + ':5000/';
      this.apiAdminUrl = window.location.protocol + '//' + window.location.hostname + ':5000/v1';
      this.stripePublicKey = 'pk_test_LQurSYY0LHfFQt5zRFrrkf3B';
      // this.mid = 'mid-02a20450-48bb-11ed-969a-b91cc70d7858';
      // this.apiUrl = window.location.protocol + '//' + 'apioblingo.uipep.com' + '/';
      // this.apiAdminUrl = window.location.protocol + '//' + 'apioblingo.uipep.com' + '/v1';
      // this.secret = 'c6204822-ba9a-4fbc-a260-dc4ead78bdd3';
      this.mid = 'mid-e05c2c80-49ec-11ed-bb60-c30c95cf92e5';
      this.secret = '2fe8ef7e-39c8-4aeb-b090-1b9d247fb23f';
    } else {
      this.apiUrl = window.location.protocol + '//' + 'apioblingo.uipep.com' + '/';
      this.apiAdminUrl = window.location.protocol + '//' + 'apioblingo.uipep.com' + '/v1';
      this.mid = 'mid-e05c2c80-49ec-11ed-bb60-c30c95cf92e5';
      this.secret = '2fe8ef7e-39c8-4aeb-b090-1b9d247fb23f';
    }
  }
}
