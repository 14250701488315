import {Injectable} from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
// import 'firebase/getPurchasedProduct';
// import 'firebase/users';
import {environment} from '../../environments/environment';
import {IProduct} from '../data/api.service';
import * as uuid from 'uuid';
import {BehaviorSubject} from 'rxjs';
import {TsiMappingService} from '../views/app/dashboards/tsi-mapping.service';
import {HttpClient} from '@angular/common/http';
import {AppConfig} from "../app.config";

firebase.initializeApp(environment.firebase);

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  public merchant = new BehaviorSubject<any>('');
  public selectItem = new BehaviorSubject<any>('');
  public productCheckoutObj = new BehaviorSubject<any>('');
  public getPurchasedItems = new BehaviorSubject<any>('');
  public products = new BehaviorSubject<any>('');
  public uid: any;
  public availableProducts: any;
  public availablePampletsSubject: any = new BehaviorSubject<any>([]);
  public availablePampletsSubjectObservable = this.availablePampletsSubject.asObservable();
  public checkoutItemsSubject: any = new BehaviorSubject<any>([]);
  public checkoutItemsSubjectObservable = this.checkoutItemsSubject.asObservable();
  public productStatus = {
    status: 'charge'
  };

  constructor(private tsiMapping: TsiMappingService, public http: HttpClient, private config: AppConfig) {
    this.selectItem.subscribe((val) => {
      // console.log(val);
      this.getPurchasedProduct();
    });
    this.getPurchasedItems.subscribe((val) => {
      // console.log(val);
    });
  }

  get availablePampletsData() {
    return this.availablePampletsSubject.value;
  }

  get checkoutItemsData() {
    return this.checkoutItemsSubject.value;
  }

  isSelected(p: IProduct) {
    const checkoutItems = this.checkoutItemsSubject.value;
    return checkoutItems.findIndex(x => x.id === p.id) > -1;
  }

  getIndex(p: IProduct) {
    const checkoutItems = this.checkoutItemsSubject.value;
    return checkoutItems.findIndex(x => x.id === p.id);
  }

  addToCheckout(item) {
    const checkoutitems = this.checkoutItemsSubject.value;
    if (this.isSelected(item)) {
      checkoutitems.splice(this.getIndex(item), 1);
    } else {
      checkoutitems.push(item);
    }
    this.checkoutItemsSubject.next(checkoutitems);
  }

  removeItem(item) {
    const checkoutitems = this.checkoutItemsSubject.value;
    checkoutitems.splice(this.getIndex(item), 1);
    this.checkoutItemsSubject.next(checkoutitems);
  }

  public async getusers(number) {
    console.log(number);
    console.log(typeof (number));
    const db = firebase.firestore();
    return await db.collection('users').where('phonenumber', '==', number).get();

    // const doc = await cityRef;
    // if (!doc.exists) {
    //   console.log('No such document!');
    // } else {
    //   console.log('Document data:', doc.data());
    //   console.log(doc);
    // }
  }

  public mobiledata(number) {
    const db = firebase.firestore();
    db.collection('users')
      .add({
        phonenumber: number,
        issignin: true,
      })

      .then(function(docRef) {
        console.log('Document written with ID: ', docRef.id);
      })
      .catch(function(error) {
        console.error('Got an error: ', error);
      });
  }

  public setPurchasedProduct(item, status) {
    console.log('inside set purchased products');
    console.log(item);
    const myId = uuid.v4();
    const date = new Date();
    const isoDate = date.toLocaleString();
    this.selectItem.subscribe((val) => {
      this.availableProducts = val;
      // console.log(val);
    });

    // const availableProduct = JSON.parse(localStorage.getItem('checkout'));
    // console.log(this.availableProducts);
    const phoneNo = JSON.parse(localStorage.getItem('phonenum'));
    const userId = phoneNo;
    const productId = [];
    const productName = [];
    const cost = [];
    console.log(userId);
    for (const item of this.availableProducts) {
      productName.push(item.name);
      productId.push(item.id);
      cost.push(item.price.$numberDecimal);
    }
    let totalCost = 0;
    for (const item of cost) {
      totalCost += parseFloat(item);
    }
    const availableCheckout = {
      id: myId,
      status,
      product_id: item.id,
      product_name: item.name,
      user_id: userId,
      cost: item.price.$numberDecimal,
      timestamp: isoDate,
      paymentdetails: 'not available',
    };
    let response = '';
    const db = firebase.firestore();
    db.collection('Purchased Products')
      .add(availableCheckout)
      .then(function(docRef) {
        response = 'successful';
        console.log('Document written with ID: ', docRef.id);
      })
      .catch(function(error) {
        console.error('Got an error: ', error);
      });
    return response;
  }

  public updatePurchasedProduct(uid) {
    // console.log(uid);
    status = 'paid';
    console.log('inside updatePurchasedProduct');
    const db = firebase.firestore();
    db.collection('Purchased Products')
      .doc(uid)
      .update({
        status,
      })
      .then(function() {
        console.log('Document Updated: ', '');
      })
      .catch(function(error) {
        console.error('Got an error: ', error);
      });
    this.getPurchasedProduct();
  }

  // public getPurchasedProduct() {
  //   let purchasedProducts = [];
  //   let productCheckoutItem = [];
  //   let phoneNo = JSON.parse(localStorage.getItem('phonenum'));
  //   var db = firebase.firestore();
  //   // db.collection('Purchased Products').snapshotChanges().pipe(map(actions.map( a => {
  //   //   const data = a.payload.doc.data();
  //   //   const id = a.payload.doc.id;
  //   // }
  //   // firebase.database().ref('Purchased Products').once('value',   function(snapshot) {
  //   //   snapshot.forEach(function(childSnapshot) {
  //   //     var childKey = childSnapshot.key;
  //   //     var childData = childSnapshot.val();
  //   //     // ...
  //   //   });
  //   // });
  //   db.collection('Purchased Products').get().then((subCollectionSnapshot) => {
  //     subCollectionSnapshot.forEach((subDoc) => {
  //       // console.log(subDoc.id);
  //       // console.log(subDoc.data());
  //       this.uid = subDoc.id;
  //       // console.log(subDoc.id)
  //       // console.log(subDoc.data());
  //       if(subDoc.data().user_id == phoneNo) {
  //         purchasedProducts.push(subDoc.data());
  //       }
  //       if(subDoc.data().user_id == phoneNo && subDoc.data().status == "charge") {
  //         let item = {
  //           "item": subDoc.data(),
  //           "uid": subDoc.id
  //         }
  //         productCheckoutItem.push(item);
  //       }
  //     });
  //   }).then(() => {
  //     this.productCheckoutObj.next(productCheckoutItem);
  //     this.getPurchasedItems.next(purchasedProducts);
  //     return purchasedProducts;
  //   })
  // }

  public getPurchasedProduct() {
    return new Promise((resolve, reject) => {
      const purchasedProducts: IProduct[] = [];
      const productCheckoutItem: IProduct[] = [];
      const token = localStorage.getItem('token');
      this.tsiMapping.getPurchases(token).subscribe((response: any) => {
        response.data.forEach((data) => {
          for (const i in data.products) {
            purchasedProducts.push({
              ...data.products[i],
              status: data.status
            } as IProduct);
            if (data.status === 'notPaid') {
              productCheckoutItem.push({
                ...data.products[i],
                status: data.status
              } as IProduct);
            }
          }
        });
        this.productCheckoutObj.next(productCheckoutItem);
        this.getPurchasedItems.next(purchasedProducts);
        resolve(productCheckoutItem);
        return purchasedProducts;
      });
    });
  }

  public processPrice(items, phone) {
    const recur = (item) => {
      console.log(item);
      console.log(item.price.$numberDecimal);
      const promise = new Promise((resolve, reject) => {
        this.tsiMapping.getCost(item.id, item).subscribe((response: any) => {
          console.log(response);
          // console.log(response.successInfo.price);
          item.cost = item.price.$numberDecimal;
          item.userId = phone;
          // console.log(this.selected);
          resolve(item);
        }, (reject: any) => {
          // reject();
        });
      });
      return promise;
    };


    const promise = new Promise((resolve, reject) => {
      const allitems = [];
      for (const i in items) {
        allitems.push(recur(items[i]));
      }
      Promise.all(allitems).then(value => {
        for (let i = 0; i < items.length; i++) {
          items[i].price = value[i].cost;
          items[i].image = value[i].image;
          items[i].name = value[i].name;
        }
        resolve(items);
      }, failed => {
        reject();
      });
    });
    return promise;
  }
  public payNow = () => {
    return new Promise((resolve, reject) => {
      this.tsiMapping.payNowForMerchant(localStorage.getItem('token'), this.merchant.value.id).subscribe(async (response: any) => {
        console.log(response);
        if (response.status === 'SUCCESS') {
          resolve('Charge Successful!');
        } else if (response.status === 'CredLimit') {
          reject('Need to pay!, Oblingo Credit limit reached');
        }
      }, (error) => {
        reject('Error');
      });
    });
  }
  public chargeAPI = (products) => {
    const prom = new Promise((resolve, reject) => {
      const temp = [];
      let purchasedvalue = 0;
      for (const i in products) {
        temp.push({
          name: products[i].name,
          price: products[i].price.$numberDecimal,
          imageurl: products[i].imgUrl,
          quantify: 1
        });
        purchasedvalue += parseFloat(products[i].price.$numberDecimal);
      }
      const object = {
        products: temp,
        purchse_value: purchasedvalue,
        merchant_id: this.merchant.value.id,
        status: 'notPaid',
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        isDeleted: false,
        isActive: true
      };
      this.tsiMapping.chargeApi(object, localStorage.getItem('token'), this.merchant.value.id).subscribe(async (response: any) => {
        console.log(response);
        if (response.status === 'SUCCESS') {
          resolve('Charge Successful!');
        } else if (response.status === 'CredLimit') {
          reject('Need to pay!, Oblingo Credit limit reached');
        }
      }, (error) => {
        if (error.error && error.error.status === 'VALIDATION_ERROR') {
          reject(error.error.message);
        } else {
          reject('Error');
        }
      });
    });
    return prom;
  }

  public async availableProduct() {
    const products = [];
    const data: any = await this.http.post(this.config.apiUrl + 'merchant/auth/loginByMid', {
      mid: this.config.mid,
      secret: this.config.secret
    }).toPromise();
    this.merchant.next(data.data);
    const results: any = await this.http.get(this.config.apiUrl + 'merchant/getProducts', {
      headers: {
        Authorization: 'Bearer ' + this.merchant.value.token
      }
    }).toPromise();
    // if (window.location.hostname !== 'localhost') {
      for (const i in results.data) {
        if (results.data[i]) {
          results.data[i].imgUrl = this.config.apiUrl + results.data[i].imgUrl;
        }
      // }
      this.availablePampletsSubject.next(results.data);
    }
    // console.log(products);
    return products;
  }

  public async availableProductFirebase() {
    const products = [];
    const db = firebase.firestore();
    await db.collection('available product')
      .get()
      .then((subCollectionSnapshot) => {
        subCollectionSnapshot.forEach((subDoc) => {
          // console.log(subDoc.data());
          products.push(subDoc.data());
        });
      });
    await this.processPrice(products, JSON.parse(localStorage.getItem('phonenum')));
    this.availablePampletsSubject.next(products);
    // console.log(products);
    return products;
  }
}
