import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gradient-card',
  templateUrl: './gradient-card.component.html',
})
export class GradientCardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
