import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Type, Provider } from '@angular/core';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { ViewsModule } from './views/views.module';
import { TranslateModule } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { LayoutContainersModule } from './containers/layout/layout.containers.module';
import { HashLocationStrategy, LocationStrategy} from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { CommonModule } from '@angular/common';
import { TsiMappingService } from './views/app/dashboards/tsi-mapping.service';
import { FirebaseService } from './services/firebase.service';
import { AppConfig } from './app.config';
// declare module 'ngx-bootstrap' {
//   interface ModuleWithProviders<T = any> {
//     ngModule: Type<T>;
//     providers?: Provider[];
//   }
// }

@NgModule({
  imports: [
    BrowserModule,
    // AngularFireModule,
    ViewsModule,
    AppRoutingModule,
    LayoutContainersModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      //closeButton: true,
    }),
    AngularFireModule.initializeApp(environment.firebase),
  ],
  declarations: [
    AppComponent
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}, TsiMappingService,FirebaseService, AppConfig],
  bootstrap: [AppComponent]
})
export class AppModule { }
