const data = [
  {
    id: 1,
    imgUrl: '/assets/imgUrl/products/marble-cake-thumb.jpg',
    category: 'Cakes',
    // createdAt: '02.04.2018',
    // description: 'Wedding cake with flowers Macarons and blueberries',
    name: '1 record',
    price: 0.1,
    mid: 'mid-273759b0-4581-11ed-bb1b-939d342296db',
    currency: 'USD',
    brand: '',
    status: '',
    description: 'A book of love, hope, and how determination can overcome even your destiny – Life is What You Make of It by Preeti Shenoy was in the “Top books of 2011” as per the Nielsen list which is published in Hindustan Times. It was also on the Times of India all-time best sellers of 2011. Summary of the Book Ankita, the protagonist, is everything you want to be. She has many friends, she is a big shot in college, she’s a bright student, and she even excels at cultural events. But, not all is good in the romance department. Caught in a tangle of love, emotions, success, mental health disorders, and more, don’t you want to find out if Ankita manages to come out of all of this with her spirit intact? Life is What You Make of It is here to ensure that you get a front-row seat to Ankita’s journey through life. About the Author Preeti Shenoy is one of India’s most popular authors. She has been consistently nominated for the Forbes List of the 100 Most Influential Celebrities in India. Her books have been bestsellers. Shenoy used to love reading as a child. She is also a self-taught artist who works with watercolours and oil. Some of Shenoy’s popular works include 34 Bubblegums and Candies, Life is What You Make it, The Secret Wish List, It Happens for a Reason, The One You Cannot Have, It’s All in the Planet’s, and A Hundred Little Flames.',
    addedBy: '633ee2b5caa926d5307ea638',
    createdAt: '2022-10-06T14:47:59.187+0000',
    updatedAt: '2022-10-06T14:47:59.187+0000',
    isDeleted: false,
    isActive: true
  }
  // },
  // {
  //   id: 2,
  //   name: 'Fat Rascal',
  //   category: 'Cupcakes',
  //   imgUrl: '/assets/imgUrl/products/fat-rascal-thumb.jpg',
  //   createdAt: '01.04.2018',
  //   purchase: '9/100',
  //   status: 'PROCESSED',
  //   statusColor: 'secondary',
  //   description: 'Cheesecake with chocolate cookies and Cream biscuits',
  //   sales: 1240,
  //   stock: 48
  // },
  // {
  //   id: 3,
  //   name: 'Fat Rascal',
  //   category: 'Cupcakes',
  //   imgUrl: '/assets/imgUrl/products/fat-rascal-thumb.jpg',
  //   createdAt: '01.04.2018',
  //   purchase: '9/100',
  //   status: 'PROCESSED',
  //   statusColor: 'secondary',
  //   description: 'Cheesecake with chocolate cookies and Cream biscuits',
  //   sales: 1240,
  //   stock: 48
  // },
  // {
  //   id: 4,
  //   name: 'Goose Breast',
  //   imgUrl: '/assets/imgUrl/products/goose-breast-thumb.jpg',
  //   category: 'Cakes',
  //   createdAt: '21.03.2018',
  //   purchase: '5/100',
  //   status: 'PROCESSED',
  //   statusColor: 'secondary',
  //   description: 'Chocolate cake with berries',
  //   sales: 1014,
  //   stock: 41
  // },
  // {
  //   id: 5,
  //   name: 'Petit Gâteau',
  //   category: 'Cupcakes',
  //   imgUrl: '/assets/imgUrl/products/petit-gateau-thumb.jpg',
  //   createdAt: '02.06.2018',
  //   purchase: '8/100',
  //   status: 'ON HOLD',
  //   statusColor: 'primary',
  //   description: 'Chocolate cake with mascarpone',
  //   sales: 985,
  //   stock: 23
  // },
  // {
  //   id: 6,
  //   name: 'Salzburger Nockerl',
  //   imgUrl: '/assets/imgUrl/products/salzburger-nockerl-thumb.jpg',
  //   category: 'Desserts',
  //   createdAt: '14.07.2018',
  //   purchase: '5/100',
  //   status: 'PROCESSED',
  //   statusColor: 'secondary',
  //   description: 'Wedding cake decorated with donuts and wild berries',
  //   sales: 962,
  //   stock: 34
  // },
  // {
  //   id: 7,
  //   name: 'Napoleonshat',
  //   imgUrl: '/assets/imgUrl/products/napoleonshat-thumb.jpg',
  //   category: 'Desserts',
  //   createdAt: '05.02.2018',
  //   purchase: '30/100',
  //   status: 'PROCESSED',
  //   statusColor: 'secondary',
  //   description: 'Cheesecake with fresh berries and mint for dessert',
  //   sales: 921,
  //   stock: 31
  // },
  // {
  //   id: 8,
  //   name: 'Cheesecake',
  //   imgUrl: '/assets/imgUrl/products/cheesecake-thumb.jpg',
  //   category: 'Cakes',
  //   createdAt: '18.08.2018',
  //   purchase: '27/100',
  //   status: 'ON HOLD',
  //   statusColor: 'primary',
  //   description: 'Delicious vegan chocolate cake',
  //   sales: 887,
  //   stock: 21
  // },
  // {
  //   id: 9,
  //   name: 'Financier',
  //   imgUrl: '/assets/imgUrl/products/financier-thumb.jpg',
  //   category: 'Cakes',
  //   createdAt: '15.03.2018',
  //   purchase: '87/100',
  //   status: 'ON HOLD',
  //   statusColor: 'primary',
  //   description:
  //     'White chocolate strawberry yogurt cake decorated with fresh fruits and chocolate',
  //   sales: 865,
  //   stock: 53
  // },
  // {
  //   id: 10,
  //   name: 'Genoise',
  //   imgUrl: '/assets/imgUrl/products/genoise-thumb.jpg',
  //   category: 'Cupcakes',
  //   createdAt: '11.06.2018',
  //   purchase: '87/100',
  //   status: 'PROCESSED',
  //   statusColor: 'secondary',
  //   description: 'Christmas fruit cake, pudding on top',
  //   sales: 824,
  //   stock: 55
  // },
  // {
  //   id: 11,
  //   name: 'Gingerbread',
  //   imgUrl: '/assets/imgUrl/products/gingerbread-thumb.jpg',
  //   category: 'Cakes',
  //   createdAt: '10.04.2018',
  //   purchase: '54/100',
  //   status: 'ON HOLD',
  //   statusColor: 'primary',
  //   description: 'Wedding cake decorated with donuts and wild berries',
  //   sales: 714,
  //   stock: 12
  // },
  // {
  //   id: 12,
  //   name: 'Magdalena',
  //   imgUrl: '/assets/imgUrl/products/magdalena-thumb.jpg',
  //   category: 'Cakes',
  //   createdAt: '22.07.2018',
  //   purchase: '60/100',
  //   status: 'PROCESSED',
  //   statusColor: 'secondary',
  //   description: 'Christmas fruit cake, pudding on top',
  //   sales: 702,
  //   stock: 14
  // },
  // {
  //   id: 13,
  //   name: 'Parkin',
  //   imgUrl: '/assets/imgUrl/products/parkin-thumb.jpg',
  //   category: 'Cakes',
  //   createdAt: '22.08.2018',
  //   purchase: '73/100',
  //   status: 'ON HOLD',
  //   statusColor: 'primary',
  //   description:
  //     'White chocolate strawberry yogurt cake decorated with fresh fruits and chocolate',
  //   sales: 689,
  //   stock: 78
  // },
  // {
  //   id: 14,
  //   name: 'Streuselkuchen',
  //   imgUrl: '/assets/imgUrl/products/streuselkuchen-thumb.jpg',
  //   category: 'Cakes',
  //   createdAt: '22.07.2018',
  //   purchase: '43/100',
  //   status: 'PROCESSED',
  //   statusColor: 'secondary',
  //   description: 'Delicious vegan chocolate cake',
  //   sales: 645,
  //   stock: 55
  // },
  // {
  //   id: 15,
  //   name: 'Tea loaf',
  //   imgUrl: '/assets/imgUrl/products/tea-loaf-thumb.jpg',
  //   category: 'Cakes',
  //   createdAt: '10.09.2018',
  //   purchase: '87/100',
  //   status: 'ON HOLD',
  //   statusColor: 'primary',
  //   description: 'Cheesecake with fresh berries and mint for dessert',
  //   sales: 632,
  //   stock: 20
  // },
  // {
  //   id: 16,
  //   name: 'Merveilleux',
  //   imgUrl: '/assets/imgUrl/products/merveilleux-thumb.jpg',
  //   category: 'Cakes',
  //   createdAt: '18.02.2018',
  //   purchase: '87/100',
  //   status: 'ON HOLD',
  //   statusColor: 'primary',
  //   description: 'Chocolate cake with mascarpone',
  //   sales: 621,
  //   stock: 6
  // },
  // {
  //   id: 17,
  //   name: 'Fruitcake',
  //   imgUrl: '/assets/imgUrl/products/fruitcake-thumb.jpg',
  //   category: 'Cakes',
  //   createdAt: '12.01.2019',
  //   purchase: '87/100',
  //   status: 'PROCESSED',
  //   statusColor: 'secondary',
  //   description: 'Chocolate cake with berries',
  //   sales: 595,
  //   stock: 17
  // },
  // {
  //   id: 18,
  //   name: 'Newsletter for Gardening enthusiasts ',
  //   imgUrl: '/assets/imgUrl/products/bebinca-thumb.jpg',
  //   category: 'Cakes',
  //   createdAt: '04.02.2019',
  //   purchase: '87/100',
  //   status: 'PROCESSED',
  //   statusColor: 'secondary',
  //   description: 'Homemade cheesecake with fresh berries and mint',
  //   sales: 574,
  //   stock: 16
  // },
  // {
  //   id: 19,
  //   name: 'Cremeschnitte',
  //   imgUrl: '/assets/imgUrl/products/cremeschnitte-thumb.jpg',
  //   category: 'Desserts',
  //   createdAt: '04.03.2018',
  //   purchase: '87/100',
  //   status: 'ON HOLD',
  //   statusColor: 'primary',
  //   description: 'Cheesecake with chocolate cookies and Cream biscuits',
  //   sales: 562,
  //   stock: 9
  // },
  // {
  //   id: 20,
  //   name: 'Soufflé',
  //   imgUrl: '/assets/imgUrl/products/souffle-thumb.jpg',
  //   category: 'Cupcakes',
  //   createdAt: '16.01.2018',
  //   purchase: '87/100',
  //   status: 'ON HOLD',
  //   statusColor: 'primary',
  //   description: 'Wedding cake with flowers Macarons and blueberries',
  //   sales: 524,
  //   stock: 14
  // }
];

export default data;
