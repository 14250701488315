import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppConfig} from '../app.config';

// import { NotificationsService, NotificationType } from 'angular2-notifications';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  showMobileMenu = false;
  // mid = 'mid-d495e770-2d9e-11ed-8a39-db0a8a5eefdb';

  constructor(private http: HttpClient, private config: AppConfig) {

  }


  register(number) {
    console.log(number);
    return this.http.post(this.config.apiUrl + 'client/auth/send_login_otp', {
      mobileNo: number,
      mid: this.config.mid
    });
  }

  otp(value) {
    console.log(value);
    return this.http.post(this.config.apiUrl + 'client/auth/login_with_otp', {
      mobileNo: value.number,
      code: value.code
    });
  }
  loginWithPhoneMidSecret(value) {
    // Note: use this api from backend only. never expose your secret
    console.log(value);
    return this.http.post(this.config.apiUrl + 'client/auth/login_with_phone_mid', {
      mobileNo: value,
      mid: this.config.mid,
      secret: this.config.secret,
      mobileNoVerified: false
    });
  }

  product() {
    return this.http.post(this.config.apiUrl + 'client/auth/login_with_otp', {
      mid: this.config.mid
    });
  }


}

